:root {
  --black: #0D0D0D;
  --grey: #CCCCCC;
}


@media print {

  @page {
    margin-top: 8mm;
    margin-bottom: 15mm;
    margin-left: 15mm;
    margin-right: 20mm;
  }

  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    margin: 0 !important;
    padding: 0 !important;
    color: var(--black) !important;
    background-color: #fff !important;
    font-size: 16px !important;
  }

  h1, h2, h3, h4, h5, h6, .preamble, p {
    max-width: 100% !important;
    margin-top: 0 !important;
  }

  h1 {
    font-size: 2rem !important;
    line-height: 2.25rem !important;
    margin-bottom: 1rem !important;
  }
  
  h2 {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    margin-bottom: 0.5rem !important;
  } 
  
  h3 {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
    margin-bottom: 0.5rem !important;
  }

  h4 {
    font-size: 1rem !important;
    line-height: 1.625rem !important;
    margin-bottom: 0.5rem !important;
  }

  h5 {
    font-size: 1.125rem !important;
    line-height: 1.625rem !important;
    margin-bottom: 0.5rem !important;
  }

  h6 {
    font-size: 1.125rem !important;
    line-height: 1.625rem !important;
    margin-bottom: 0.5rem !important;
  }

  p {
    font-size: 0.875rem !important;
    line-height: 1.625rem !important;
    margin-bottom: 1.125rem !important;
  }

  /*LÄNKARNA BEHÖVER MATCHA p I FONT SIZE OCH LINE HEIGHT*/
  a:not(h1, h2, h3, h4, h5, h6, span), 
  a *:not(h1, h2, h3, h4, h5, h6, span), 
  a:hover:not(h1, h2, h3, h4, h5, h6, span), 
  a:active:not(h1, h2, h3, h4, h5, h6, span), 
  a:visited:not(h1, h2, h3, h4, h5, h6, span), 
  a::after:not(h1, h2, h3, h4, h5, h6, span) {
    font-size: 0.875rem !important;
    line-height: 1.625rem !important;
  }

  span:not(.breadcrumbs_breadcrumbs__THrCk .breadcrumbs_breadcrumbsListItem__uEZVX span, .afa-shared-document-type) {
    font-size: 0.875rem !important;
    line-height: 1.625rem !important;
    margin: 0rem !important;
  }

  p > a {
    font-size: 0.875rem !important;
    line-height: 1.625rem !important;
    margin-bottom: 1.125rem !important;
  }

  .preamble {
    font-size: 1rem !important;
    line-height: 1.625rem !important;
    margin-bottom: 1.125rem !important;
  }

  ul li p {
    margin: 0 !important;
  }
  
  a, a *, a:hover, a:active, a:visited, a::after {
    margin: 0 !important;
    color: var(--black) !important;
    filter: grayscale(100%);
  }

  .LinkedHeading_linkedHeading___SEJq .LinkedHeading_headingLinkIcon__44da7 {
    width: 1.75rem !important;
    height: 1.75rem !important;
    min-width: 1.75rem !important;
    min-height: 1.75rem !important;
    margin-top: 0.125rem !important;
  }

  /*Ta bort färgerna*/
  .InsuranceHandbookPage_insuranceHandbookPage__Yavpe img, 
  .InsuranceHandbookPage_insuranceHandbookPage__Yavpe picture,
  .ExtendedBulletList_bulletList__Z09VP .ExtendedBulletList_listItem__UrWAQ.ExtendedBulletList_check__XoGqM::before,
  .afa-shared-timeline *, 
  .afa-shared-chat-card {
    filter: grayscale(100%) !important;
  }

  .breadcrumbs_breadcrumbs__THrCk{
    display: block !important;
  }

  .breadcrumbs_breadcrumbs__THrCk .breadcrumbs_breadcrumbsListItem__uEZVX a {
    font-size: 0.825rem !important;
  }

  /* Hide these components completely */
  .afa-shared-footer-top, 
  .afa-shared-footer-bottom .social-media-wrapper, 
  .section-one, .section-two, .form-container, 
  .shotCuts_shortCuts__kKt97, 
  .shareImage_imageWrapper__bnLcO, 
  .insurancePicker_insurancePicker__gA0ZM, 
  .imageBlock_imageBlock__VlBvs .imageBlock_button__PeiKe,
  .afa-shared-top-image,
  .InsuranceSector_link__EVcf_,
  .afa-shared-text.PhotoInfo_photographer__9HBxq,
  .printButton_printButtonContainer__KUUHv,
  .PageFooter_contactAreaWrapper__XQKVL,
  .InsuranceHandbookPage_toggleButtonWrapper__7_TsZ,
  .PageWrapper_photoInfo__EI9Im, /*Information under bild, t.ex fotograf etc*/
  .vngage-banner /*Chattbotten*/,
  ._hj-zRk2h__Feedback__feedback._hj-z1NGf__Feedback__button /*Hotjar feedback knapp*/,
  .InsuranceSector_insuranceSector__pidUJ /*Meddelande om användaren inte valt någon målgrupp*/,
  header *:not(.afa-shared-top-bar, .afa-shared-top-bar .column-one *, .column-one, .breadcrumbs_breadcrumbs__THrCk, .breadcrumbs_breadcrumbs__THrCk  *),
  .InsuranceHandbookPage_insuranceHandbookPage__Yavpe > .InsuranceHandbookPage_contentWrapper__QUL6U > .InsuranceHandbookPage_wrapper__zy6_y > .afa-shared-grid.undefined.inheritedContentAreaWrapper,
  img:not(.InsuranceHandbookPage_insuranceHandbookPage__Yavpe img, .print-logo, .afa-shared-header-logo img), 
  picture:not(.InsuranceHandbookPage_insuranceHandbookPage__Yavpe picture, .print-logo, .afa-shared-header-logo picture)
  {
    display:none !important;
  }

  .afa-shared-link.button {
    width: fit-content !important;
  }

  /* Remove the borders from these items */
  .afa-shared-accordion.print-noborder > .accordion-item > .content-wrapper {
    border: none !important;
  }

  /* Removing background colors, making borders black and text black */
  .TeaserList_teaserList__NwHJ7 .TeaserList_list__y__NU.TeaserList_border__B794J,
  .InformationArea_informationArea__EiAu7,
  .afa-shared-link.button,
  .afa-shared-button,
  .afa-shared-card,
  .afa-shared-message,
  .afa-shared-date-sign
  {
    background-color: transparent !important;
    border: var(--black) solid 1px !important;
    color: var(--black) !important;
  }

  .afa-shared-date-sign {
    line-height: 1.625rem !important;
    width: 3rem !important;
    height: 3rem !important;
    margin-bottom: 1rem !important;
  }

  .TeaserList_teaserList__NwHJ7 .TeaserList_listItem__Nn_gQ:not(:last-child) {
    border-bottom: var(--black) solid 1px !important;
    color: var(--black) !important;
  }

  .fileList_grid__VvB62 .fileListCard_fileListCard__KKVmB:not(:last-child),
  .TeaserList_teaserList__NwHJ7 .TeaserList_listItem__Nn_gQ:not(:last-child) {
    border-bottom: var(--black) solid 1px !important;
  }

  /* Fixing grid issues that arise because the print goes into mobile first but we want desktop */
  .contentArea_contentArea__es0pq.contentArea_notebookMultiCol__aHbDU.contentArea_desktopMultiCol__q5Z1v.contentArea_sectionBlock__Oqt8D {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  /*Accordion*/
  .content-wrapper {
    height: auto !important;
    opacity: 1 !important;
    padding: 1rem !important;
  }

  .content-wrapper .content-wrapper {
    border: var(--black) solid 1px; 
    border-top: none;
  }

  .afa-shared-accordion .content-wrapper {
    border: var(--black) solid 1px !important; 
    border-top: none !important;
  }

  .afa-shared-accordion .content-wrapper >* {
    height: auto !important;
    visibility: visible !important;
    overflow: visible !important;
    position: static !important;
  }

  .afa-shared-accordion .header-button.faq>* {
    color: var(--black) !important;
  }

  .afa-shared-accordion .header-button.faq {
    background: transparent !important;
    border: var(--black) solid 1px;
  }
  
  .afa-shared-accordion .content-wrapper p:last-child {
    margin-bottom: 0rem !important;
  }
  
  /* Icons, tags */
  .afa-shared-icon {
    background-color: var(--black) !important;
  }

  p > a > .afa-shared-icon {
    width: 0.875rem !important;
    height: 0.875rem !important;
    font-size: 0.875rem !important;
  }

  .afa-shared-icon.sm {
    width: 0.875rem !important;
    height: 0.875rem !important;
    font-size: 0.875rem !important;
    line-height: 1.625rem !important;
  }

  .HeroBlock_mobilePreamble__fOuMj p {
    margin-bottom:0 !important;
  }

  .InsuranceSector_insuranceSector__pidUJ .InsuranceSector_linkWrapper__E0P0U span,
  .afa-shared-document-type,
  .afa-shared-tag {
    background-color: var(--grey) !important;
    color: var(--black) !important;
  }

  .afa-shared-document-type {
    font-size: 0.625rem !important;
    margin-right: 0.5rem !important;
    margin-top: 0.375rem !important;
  }

  .afa-shared-text.paragraph, .afa-shared-text.preamble {
    max-width: 100% !important;;
  }

  .link-span span {
    color: var(--black) !important;
  }

  .pageList_listItem__dC63q {
    border: var(--black) solid 1px !important;
    padding: 1rem;
  }

  /* Topbar and top-image */
  .afa-shared-header-logo img {
    display: block !important;
    filter: grayscale(100%);
    margin: 0 !important;
  }

  .afa-shared-top-bar {
    background-color: white;
  }

  /* Cards and wrappers */
  .afa-shared-fact-card{
    background-color: #ffffff !important;
    border: var(--black) solid 1px;
  }

  .afa-shared-list-card {
    gap: 0 !important;
  }

  .afa-shared-grid,
  .search-button {
    background-color: transparent !important;
  }

  .afa-shared-card  {
    padding: 1rem !important;
  }

  .afa-shared-contact-card {
    border-color: var(--black);
  }

  .afa-shared-accordion, .afa-shared-card {
    break-inside: avoid;
  }

  .afa-shared-accordion .accordion-item{
    border-color: var(--black) !important;
  }
  
  .shareImage_shareImage__RWztf .shareImage_article__4DJvW {
    border-color: var(--black) !important;
  }

  .afa-shared-timeline .content{
    background-color: transparent;
    border: var(--black) solid 1px ;
  }

  .section-wrapper * {
    color: var(--black) !important;
  }

  .print-logo {
    display: block !important;
    text-align: center !important;
    margin: auto !important;
    width: 127px !important;
    height: 127px !important;
  }

  .InsuranceSector_fallbackWrapper__wpTPT {
    background: transparent !important;
  }

  .InformationArea_informationArea__EiAu7,
  .pageList_list__EvRtz,
  .pageList_errorWrapper__WzdMO {
    border-color: var(--black) !important;
  }

  /* Files and downloads */
  .fileList_fileList__PwNG9 ul{
    border-color: var(--black) !important;
  }

  .fileListCard_downloadSection__1sIr_,
  .download-section {
    color: var(--black) !important;
  }

  .fileListCard_fileListCard__KKVmB,
  .afa-shared-file-block  {
    border-color: var(--black) !important;
  }

  /* Videoblock, removing video, adding [Video] before heading, adding url at the end, paragraph gives url some space */
  .afa-shared-video-card {
    background-color: transparent !important;
    border: var(--black) solid 1px !important;
  }

  .afa-shared-video-card .video-wrapper {
    display:none;
  }

  .afa-shared-video-card .afa-shared-heading::before {
    content: "[Video] ";
  }

  .VideoBlock_videoBlock__buFaH {
    position: relative !important;
  }

  .afa-shared-video-card .text-wrapper {
    padding-bottom: 2rem !important;
  }

  .VideoBlock_videoBlock__buFaH::after {
    content: attr(data-videolinkurl);
    position:absolute;
    bottom: 1.5rem;
    left: 1rem;
    text-decoration: underline;
    font-size: 1.25rem;
  }

  .HeroBlock_heroBlock__x1Y0q {
    background-color: transparent !important;
    color: var(--black) !important;
  }

  .HeroBlock_heroBlock__x1Y0q .HeroBlock_heading__l2iuU, 
  .HeroBlock_heroBlock__x1Y0q .HeroBlock_text__IKfUy {
    color: var(--black) !important;
  }

  .HeroBlock_linksWrapper__m_OYy {
    display: none !important;
  }

  .afa-shared-icon.icon-toggle {
    -webkit-mask-image: url(assets/remove.svg) !important;
  }

  /* Tables */
  .afa-shared-table *,
  .ExtendedBulletList_bulletList__Z09VP {
    filter: grayscale(100%);
  }

  .afa-shared-table.multi-columns thead {
    display: table-header-group !important;
    position: unset !important;
  }

  .afa-shared-table tr:nth-child(even) {
    background-color: #efefef !important;
  }

  .afa-shared-table td:nth-child(odd),
  .afa-shared-table tr td:nth-child(even) {
    background-color: transparent !important;
  }

  .afa-shared-table tr {
    display: table-row !important;
  }

  .afa-shared-table td {
    border: var(--grey) solid 1px !important; 
  }

  .afa-shared-table td .span {
    width: 100% !important;
    border: none !important;
    padding: 0 !important;
  }

  .afa-shared-table .heading {
    display: none !important;
  }

  .tbody {
    display: table-row-group !important;
    vertical-align: middle !important;
    border-color: inherit !important;
  }

  .afa-shared-table.two-columns tr td,
  .afa-shared-table.two-columns tr td:first-child,
  .afa-shared-table th,
  .afa-shared-table th:last-child
  {
    border-color: var(--grey);
  }

  .afa-shared-table th:not(:last-child) {
    border-right: .0625rem solid var(--grey);
  }

  /* Footer */
  footer {
    break-inside: avoid;
  }

  .afa-shared-footer-bottom {
    background-color: #ffffff;
  }

  .afa-shared-footer-bottom .content {
    justify-content: center !important;
  }

  .afa-shared-footer-bottom .section-wrapper {
    padding-top: 2rem !important;
    align-items: center !important;
    gap: 1rem !important;
  }

  .afa-shared-footer-bottom .section-three, 
  .afa-shared-footer-bottom .section-four {
    align-items: center !important;
  }


  .afa-shared-footer-bottom .section-four .orgNumber-wrapper > div a, 
  .afa-shared-footer-bottom .section-four .orgNumber-wrapper > div a * {
    color: var(--black) !important;
  }

  /* Payment days */

  .PaymentDaysBlock_paymentDaysCalendarView__ZzHDR{
    display: grid !important;
    grid-template-columns:  2fr 1fr !important;
    gap: 1rem !important;
    grid-gap: initial !important;
    max-width: 826px !important;
  }
  .PaymentDaysBlock_paymentDaysCalendarView__ZzHDR .PaymentDaysBlock_container__EQQr3 {
    max-width: 540px !important;
  }
  .PaymentDaysBlock_paymentDaysCalendarView__ZzHDR .PaymentDaysBlock_firstHeading__50FDM {
    background: #a1a1a1 !important;
    min-height: 28px !important;
    padding: 0.5rem 0 0 0 !important;
    margin: 0 !important;
    font-size: 20px !important;

  }
  .PaymentDaysBlock_paymentDaysCalendarView__ZzHDR .PaymentDaysBlock_compensationTypeText__2sWkY{
    line-height: 140% !important;
    background: #a1a1a1 !important;
    font-size: 20px !important;
    min-height: 28px !important;
    margin: 0 !important;
  }
  .PaymentDaysBlock_paymentDaysCalendarView__ZzHDR .PaymentDaysBlock_paymentDateContainer__R6XY6{
    padding: 1rem 0 1.5rem 0 !important;
    border-color: #a1a1a1 !important;
  }
  .PaymentDaysBlock_paymentDaysCalendarView__ZzHDR .PaymentDaysBlock_paymentDateText__oqt5b{
    font-size: 20px !important;

  }
  .PaymentDaysBlock_paymentDaysCalendarView__ZzHDR .PaymentDaysBlock_paymentDate__GferV{
    font-size: 54px !important;
  }
  
  .PaymentDaysBlock_paymentDaysCalendarView__ZzHDR .PaymentDaysBlock_parentalPaymentContainer__1biZJ{
    margin-bottom: 0.5rem !important;
    max-width: 270px !important;
  }

  .PaymentDaysBlock_paymentDaysCalendarView__ZzHDR .PaymentDaysBlock_secondHeading__2E2As{
    min-height: 70px !important;
    padding-top: 0.5rem !important;
    margin: 0 !important;
    background: #a1a1a1 !important;
  }
}