:root {
  --white: #fdfdfd;

  --blue-100: #fafdff;
  --blue-200: #e6ecfb;
  --blue-300: #c8ebfa;
  --blue-400: #7dcdff;
  --blue-500: #003cd2;
  --blue-600: #020678;
  --blue-700: #010460;

  --red-100: #fef5f4;
  --red-200: #fbebea;
  --red-300: #fad1cc;
  --red-400: #f99b8f;
  --red-500: #d23124;
  --red-600: #972118;

  --yellow-100: #fffaf5;
  --yellow-200: #fcf2e6;
  --yellow-300: #ffe399;
  --yellow-400: #fab455;
  --yellow-500: #dd7d05;
  --yellow-600: #da610c;

  --blue: var(--blue-600);
  --yellow: var(--yellow-400);
}

html #CookieConsent {
  font-family: "Ambit";
  font-style: normal;
}

html #CookieConsent .cookie-popup > div {
  border-radius: 0;
}

/* white-box */

html #CookieConsent .cookie-popup > div .content {
  height: 100%;
  padding: 2rem;
}

/* heading */
html #CookieConsent .cookie-popup > div .content > h1 {
  text-align: left;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 2rem;
}

/* paragraph */
html #CookieConsent .cookie-popup > div .content > #cc-description > p {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

html #CookieConsent .readmore > div .content > #cc-description > p {
  display: none;
}

/* link */
html #CookieConsent .cookie-popup > div .content > #cc-description > a {
  color: var(--blue-600);
  font-size: 1.25rem;
  line-height: 1.75rem;
  display: inline-flex;
  align-items: center;
  margin: 0.125rem;
}

html
  #CookieConsent
  .cookie-popup
  > div
  .content
  > #cc-description
  > a:focus-visible {
  outline: 0.125rem solid var(--white);
  outline-offset: 0;
  box-shadow: 0 0 0 0.25rem var(--blue-600);
}

html #CookieConsent .cookie-popup > div .content > div > a::before {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  background: url("/images/internalLink.svg");
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;
}

/* buttons   */
html #CookieConsent .cookie-popup > div .actions > div button#cc-b-acceptall {
  /* background-color: var(--yellow-400); // ÄNDRAS I ADMINGRÄNSSNITT */
  /* color: var(--blue-600) // ÄNDRAS I ADMINGRÄNSSNITT */
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.375rem;
  height: 3.5rem;
}

html
  #CookieConsent
  .cookie-popup
  > div
  .actions
  > div
  button#cc-b-acceptall:hover {
  text-decoration: underline;
}

html #CookieConsent .cookie-popup > div .actions > div button:focus-visible {
  outline: 0.125rem solid var(--white);
  outline-offset: 0;
  box-shadow: 0 0 0 0.25rem var(--blue-600);
}

html #CookieConsent .cookie-popup > div .actions > div button#cc-b-custom {
  /* background-color: var(--white); // ÄNDRAS I ADMINGRÄNSSNITT  */
  /* color: var(--blue-600) // ÄNDRAS I ADMINGRÄNSSNITT  */
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.375rem;
  height: 3.5rem;
}

html
  #CookieConsent
  .cookie-popup
  > div
  .actions
  > div
  button#cc-b-custom:hover {
  text-decoration: underline;
}

html #CookieConsent .cookie-popup .actions > div button {
  border-radius: 0;
  -webkit-text-fill-color: var(--blue-600);
}

/* toggle and labels */
html #CookieConsent .cookie-popup .actions > div label {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  color: var(--blue-600);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  margin-top: 1rem;
  box-sizing: border-box;
}

html #CookieConsent .cookie-popup .actions > div label:nth-child(2) {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

html #CookieConsent .cookie-popup .actions > div label input {
  background: var(--white);
  /* border: 0.125rem solid var(--blue-600); */
  -webkit-appearance: unset;
  -moz-appearance: unset;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0;
  margin-left: 0.125rem;
}

html #CookieConsent .cookie-popup .actions > div label input:checked {
  background: var(--blue-600);
}

html #CookieConsent .cookie-popup .actions > div label input:focus-visible {
  outline: 0.125rem solid var(--white);
  outline-offset: 0;
  box-shadow: 0 0 0 0.25rem var(--blue-600);
}

html #CookieConsent .cookie-popup .actions > div label::before {
  content: "";
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.625rem;
  text-align: center;
  border: 0.125rem solid #020678;
  box-sizing: border-box;
  border-radius: 0rem;
}

html #CookieConsent .cookie-popup .actions > div label input:checked:after {
  content: "";
  position: absolute;
  left: 0.45rem;
  top: -0.175rem;
  display: inline-block;
  width: 0.25rem;
  height: 1rem;
  border: solid #fdfdfd;
  /* border: solid red;  */
  border-width: 0 0.1875rem 0.1875rem 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

html #CookieConsent .cookie-popup .actions > div label input::before {
  content: none;
}

/* focus state appearance override on focusable */
html #CookieConsent .cookie-popup .actions > div button:focus-visible {
  outline: -webkit-focus-ring-color auto 0.06rem;
}

html #CookieConsent .cookie-popup .processors > li button:focus-visible {
  outline: -webkit-focus-ring-color auto 0.06rem;
}

html #CookieConsent .cookie-popup .actions > div #cc-b-custom {
  margin: 0rem;
}

html #CookieConsent .cookie-popup .actions > div #cc-b-custom.accentuated {
  color: var(--blue-600);
}

/* read more - button */
html #CookieConsent .cookie-popup .details > button {
  background-color: var(--blue-600);
  font-weight: 700;
  text-align: center;
  text-decoration-line: underline;
  color: var(--white) !important;
  padding: 0;
  font-size: 1rem;
}

html #CookieConsent .cookie-popup .details > button:focus-visible {
  outline: 0.125rem solid var(--blue-600);
  outline-offset: -0.125rem;
  box-shadow: inset 0 0 0 0.25rem var(--white);
}

/* blue box */

html #CookieConsent .cookie-popup #cc-description {
  max-height: unset !important;
  padding: 0.25rem;
  margin: -0.25rem;
}

html #CookieConsent .cookie-popup .details > div,
html #CookieConsent .cookie-popup .details {
  background-color: var(--blue-600);
}

/* ul */
html #CookieConsent .cookie-popup .details #cc-details {
  padding: 1.5rem 2rem 0.875rem 2rem;
  max-width: unset;
}

html #CookieConsent .cookie-popup .details #cc-details h2 {
  color: var(--white);
  font-size: 1.75rem; /*28px*/
  line-height: 2.25rem; /*36px*/
}
html #CookieConsent .cookie-popup .details #cc-details p {
  color: var(--white);
  font-size: 1.25rem; /*20px*/
  line-height: 1.75rem; /*28px*/
}
html #CookieConsent .cookie-popup .details #cc-details ul li button {
  background-color: var(--white);
  padding: 1rem;
  justify-content: flex-start;
}

html
  #CookieConsent
  .cookie-popup
  .details
  #cc-details
  ul
  li
  button:focus-visible {
  outline: 0.125rem solid var(--white);
  outline-offset: -0.125rem;
  box-shadow: inset 0 0 0 0.25rem var(--blue-600);
}

/* accordion */

html
  #CookieConsent
  .cookie-popup
  .processors
  > li
  button
  span:first-child:after {
  display: none;
}

html #CookieConsent .cookie-popup .processors > li button:hover {
  text-decoration: underline;
}

html #CookieConsent .cookie-popup .processors > li button span:first-child {
  padding-right: 0.375rem;
}

#CookieConsent .cookie-popup .processors > li button span:first-child + span {
  width: 0;
}
html
  #CookieConsent
  .cookie-popup
  .processors
  > li
  button
  span:last-child:before {
  content: "(";
}
html
  #CookieConsent
  .cookie-popup
  .processors
  > li
  button
  span:last-child:after {
  content: ")";
}

html #CookieConsent .cookie-popup .details #cc-details ul li button:after {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  background: url("/images/add.svg") no-repeat right;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
  flex: 1 0 auto;
}
html #CookieConsent .cookie-popup .details #cc-details ul li button:after {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  background: url("/images/add.svg") no-repeat right;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
  flex: 1 0 auto;
}

html
  #CookieConsent
  .cookie-popup
  .details
  #cc-details
  ul
  li
  button[aria-expanded="true"]:after {
  background: url("/images/remove.svg") no-repeat right;
}

html #CookieConsent .cookie-popup .processors > li button span {
  font-size: 1.25rem; /*20px*/
  line-height: 1.75rem; /*28px*/
}

html #CookieConsent .cookie-popup .details #cc-details ul li ul {
  background-color: var(--white);
}

html #CookieConsent .cookie-popup .processors .items li {
  margin: 0rem 1.5rem;
  padding: 1rem 0rem;
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.25);
}

html #CookieConsent .cookie-popup .details table {
  font-size: 1rem;
  line-height: 1.375rem;
}

html #CookieConsent .cookie-popup .processors .items li:first-child {
  padding-top: 0rem;
}

html #CookieConsent .cookie-popup .processors .items li:last-child {
  border-bottom: none;
  padding-bottom: 0rem;
}

html #CookieConsent .cookie-popup .processors .items li {
  border-top: none;
}
html #CookieConsent .cookie-popup .details > div a {
  color: var(--blue-600);
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
}

html #CookieConsent .cookie-popup .details > div a:focus-visible {
  outline: 0.125rem solid var(--white);
  outline-offset: 0;
  box-shadow: 0 0 0 0.25rem var(--blue-600);
}

html #CookieConsent .cookie-popup .details > div a::before {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  background: url("/images/internalLink.svg");
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;
}

/* cookie-info/powered by */
html #CookieConsent .cookie-popup #cc-info.opened {
  color: var(--white);
  padding: 1rem 2rem 1.5rem 2rem;
}

html #CookieConsent .cookie-popup #cc-footer {
  display: none;
}

/*
For some reason collapsed blocks in the dialog are hidden with height: 0 and links
within them can therefore get focus when tabbing through the form. This is a hack to fix that.
*/
html #CookieConsent .processors .items[aria-hidden="true"] a {
  display: none;
}

/* mobile design */

@media screen and (max-width: 1239px) {
  /* white box */

  html #CookieConsent .cookie-popup > div .content {
    padding: 1rem !important;
    transform: none !important;
  }

  /* heading */
  html #CookieConsent .cookie-popup > div .content > h1 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  /* paragraph */
  html #CookieConsent .cookie-popup > div .content > #cc-description > p {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  /* link */
  html #CookieConsent .cookie-popup > div .content > #cc-description > a {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  html #CookieConsent .cookie-popup > div .content > div > a::before {
    margin-right: 0.25rem;
  }

  html #CookieConsent .cookie-popup .details > div a::before {
    margin-right: 0.25rem;
  }

  /* blue box */
  html #CookieConsent .cookie-popup .details #cc-details {
    padding: 1.5rem 1rem 0.875rem 1rem;
  }

  html #CookieConsent .cookie-popup .details #cc-details h2 {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  html #CookieConsent .cookie-popup .details #cc-details p {
    color: var(--white);
    font-size: 1rem;
    line-height: 1.375rem;
  }

  /* accordion */
  html #CookieConsent .cookie-popup .processors > li button span {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  html #CookieConsent .cookie-popup .details #cc-details ul li button {
    align-items: center;
  }
  html #CookieConsent .cookie-popup #cc-info.opened {
    color: var(--white);
    padding: 1rem;
  }
}

@media screen and (max-width: 590px) {
  html #CookieConsent .cookie-popup > div .actions {
    max-width: unset;
  }
}

@media screen and (min-width: 591px) and (max-width: 900px) and (orientation: landscape) {
  html #CookieConsent .cookie-popup.readmore > div {
    flex-direction: row;
    width: 100%;
  }

  #CookieConsent .cookie-popup.readmore .actions {
    flex-direction: column;
    gap: 1rem;
  }

  #CookieConsent .cookie-popup.readmore .actions > div {
    border: none !important;
    padding: 0 !important;
    width: 100% !important;
  }

  #CookieConsent .cookie-popup.readmore .details {
    max-height: none !important;
    position: sticky;
    top: 0;
  }
}

html #CookieConsent .cookie-popup .details {
  background-color: white;
  min-height: 0px;
}

html #CookieConsent #cc-b-details {
  background-color: white;
}

html #CookieConsent .readmore #cc-b-details {
  border-bottom: none;
}

html #CookieConsent #cc-b-details > span {
  color: var(--blue-600);
  font-weight: 400;
}

html #CookieConsent #cc-b-details > span::after {
  content: "";
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url('../public/assets/chevron_down.svg');
  background-repeat: no-repeat;
  margin-left: 0.25rem;
}

html #CookieConsent .readmore #cc-b-details > span::after {
  background-image: url('../public/assets/chevron_up.svg');
}

