@font-face {
  font-family: 'Ambit';
  src: url('/fonts/Ambit-Regular.woff2') format('woff2'), url('/fonts/Ambit-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ambit';
  src: url('/fonts/Ambit-Bold.woff2') format('woff2'), url('/fonts/Ambit-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Ambit', Arial, Helvetica, sans-serif;
  background-color: #fafdff;
  height: 100%;
  min-height: 100vh;
}

body * {
  font-family: 'Ambit', Arial, Helvetica, sans-serif;
  letter-spacing: -0.01em;
}

body.sector-picker-open,
body.quiz-modal-open,
body:has(#CookieConsent),
body.share-image-modal-open,
body.image-modal-open  {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

@media (min-width: 1240px) and (max-width: 1300px) and (-webkit-device-pixel-ratio: 1.25) {
  body.use-zoom {
    zoom: 0.8;
  }
}

@media screen and (max-width: 1239px) {
  body.mobile-menu-open,
  body.mobile-login-menu-open {
    background: #c8ebfa;
  }

  body.mobile-menu-open main,
  body.mobile-menu-open footer,
  body.mobile-menu-open [aria-label='Brödsmulor'],
  body.mobile-menu-open .operational-info {
    display: none;
  }

  body.mobile-login-menu-open main,
  body.mobile-login-menu-open footer,
  body.mobile-login-menu-open [aria-label='Brödsmulor'],
  body.mobile-login-menu-open .operational-info {
    display: none;
  }

  body.mobile-menu-open [aria-label='Toppmeny'] > div,
  body.mobile-login-menu-open [aria-label='Toppmeny'] > div {
    flex-direction: column;
    padding: 0 1rem 1rem;
  }
}

#__next {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  position: relative;
  flex: 1;
}

a {
  color: inherit;
}

a:focus-visible,
button:focus-visible {
  outline: 0.125rem solid #fdfdfd;
  outline-offset: 0;
  box-shadow: 0 0 0 0.25rem #003cd2;
}

* {
  box-sizing: border-box;
}

.grecaptcha-badge {
  visibility: hidden;
}

body.sector-picker-open .grecaptcha-badge {
  display: none !important;
}
.arabic .afa-shared-accordion .header-button h3, .arabic .afa-shared-accordion .header-button h2,.arabic .afa-shared-accordion .header-button h4 {
  text-align: right;
  +span {
    margin-left: 0;
  }
}
.arabic .afa-shared-accordion .header-button span {
  margin-left: 0;
  margin-right: 0;
}
.arabic .afa-shared-accordion .header-button span:first-child {
  margin-left: 1rem;
  margin-right: 0;
}

.accessibility-list__item .afa-shared-accordion h4 {
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
}